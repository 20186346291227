import { memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BaseWhiteBg = memo(({ children }: Props) => (
  <div className="flex justify-center">
    <div className="my-4 min-h-screen max-w-[440px] flex-1 rounded-2xl bg-white px-8 shadow-md">
      {children}
    </div>
  </div>
));
