import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

import { CutInfoType } from "./cutInfo";
import { getProduct, ProductType } from "./products";

type ResultCutInfoType = CutInfoType & {
  isRequestedShip: boolean;
  shipped: boolean;
};

export type ResultType = {
  count: number;
  uid: string;
  productId: string;
  boughtAt: Timestamp;
  result: ResultCutInfoType[];
  sessionId: string;
  numOfSpecial: number;
  customReceiptNumber: string;
  analyticsPayload: {
    transaction_id: string;
    value: number;
    currency: string;
    items: {
      item_id: string;
      item_name: string;
      quantity: number;
      price: number;
    }[];
  };
};

export const kujiResultListener = async (
  uid: string,
  sessionId: string,
  callback: (data: ResultType) => void
) => {
  const resultRef = doc(db, "kuji_results", uid, "result", sessionId);

  const unsub = onSnapshot(resultRef, (doc) => {
    callback(doc.data() as ResultType);

    unsub();
  });

  return unsub;
};

export const getKujiResults = async (
  uid: string
): Promise<{ result: ResultType; product: ProductType }[]> => {
  const productCache: { [productId: string]: ProductType } = {};
  const results: { result: ResultType; product: ProductType }[] = [];

  const resultsRef = collection(db, "kuji_results", uid, "result");
  const queryRef = query(resultsRef, orderBy("boughtAt", "desc"));
  const resultSnap = await getDocs(queryRef);
  for (const doc of resultSnap.docs) {
    const resultData = doc.data() as ResultType;
    let productData: ProductType;
    if (productCache[resultData.productId]) {
      productData = productCache[resultData.productId];
    } else {
      const _productData = await getProduct(resultData.productId);
      if (_productData === null) continue;
      productData = _productData;
      productCache[resultData.productId] = _productData;
    }
    results.push({ result: resultData, product: productData });
  }
  return results;
};

export const getKujiResultByReciptNumber = async (
  uid: string,
  receiptNumber: string
) => {
  const resultsCollection = collection(db, "kuji_results", uid, "result");
  const q = query(
    resultsCollection,
    where("customReceiptNumber", "==", receiptNumber)
  );
  const qs = await getDocs(q);
  if (qs.size > 0) {
    const doc = qs.docs[0];
    return doc.data() as ResultType;
  } else {
    return null;
  }
};

// 型定義
interface KujiResult {
  count: string; //string型で保存されているため
}

export const getTotalDrawCount = async (
  productId: string,
  uid: string
): Promise<number> => {
  const kujiResultsRef = collection(db, "kuji_results", uid, "result");

  const q = query(kujiResultsRef, where("productId", "==", productId));

  const querySnapshot = await getDocs(q);

  let total = 0;
  querySnapshot.forEach((doc) => {
    const data = doc.data() as KujiResult;
    total += parseInt(data.count, 10); // stringからintへ変換して合計
  });

  return total;
};
