import { doc, getDoc, setDoc } from "firebase/firestore";

import { db } from "../firebase";

export type Email = {
  email: string;
  uid: string;
};

export const createEmail = async (email: string, uid: string) => {
  const emailRef = doc(db, "emails", uid);
  await setDoc(emailRef, {
    email: email,
    uid: uid,
  });
};

export const getEmail: (uid: string) => Promise<Email | null> = async (
  uid: string
) => {
  const emailRef = doc(db, "emails", uid);
  const emailSnap = await getDoc(emailRef);

  if (emailSnap.exists()) {
    return { ...emailSnap.data() } as Email;
  } else {
    return null;
  }
};
