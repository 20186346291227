import { memo } from "react";

interface Props {
  type?: string;
  width?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string; // エラーメッセージ
}

export const Input = memo(
  ({
    type = "text",
    width,
    placeholder,
    value,
    name,
    readOnly,
    disabled,
    maxLength,
    minLength,
    onChange,
    errorMessage,
  }: Props) => {
    const isValidationError = errorMessage && value;
    const inputClassName = `p-0 px-2 w-full h-10 border border-[#cacaca] bg-[#f8f8f8] text-base outline-none focus:shadow-[0_0_2px_#cacaca] ${readOnly ? "p-0 border-none bg-transparent focus:shadow-none" : ""}`;

    return (
      <>
        <div className="flex">
          <input
            className={inputClassName}
            type={type}
            style={{ width }}
            placeholder={placeholder}
            value={value}
            name={name}
            readOnly={readOnly}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
          />
        </div>
        {isValidationError && (
          <p className="text-sm text-red">{errorMessage}</p>
        )}
      </>
    );
  }
);
