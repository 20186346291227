import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

import { Gender } from "../constants";
import { db } from "../firebase";

export type User = {
  uid: string;
  boughtProductIds: string[];
  gender: Gender | null;
  birthDate: Timestamp | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  lastLoginedAt: Timestamp;
  stripeId: string;
  stripeLink: string;
};

const getUserPath = (uid: string) => {
  return doc(db, "users", uid);
};

export const createNewUser = async (uid: string) => {
  await setDoc(getUserPath(uid), {
    uid: uid,
    gender: null,
    birthDate: null,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    lastLoginedAt: serverTimestamp(),
    boughtProductIds: [],
    stripeId: "",
    stripeLink: "",
  });
};

export const getUser = async (uid: string) => {
  const userSnap = await getDoc(getUserPath(uid));

  if (userSnap.exists()) {
    return { ...userSnap.data() } as User;
  } else {
    return null;
  }
};

export const updateGender = async (uid: string, gender: Gender) => {
  await updateDoc(getUserPath(uid), {
    gender,
  });
};

export const updateBirth = async (uid: string, birthDate: Timestamp) => {
  await updateDoc(getUserPath(uid), {
    birthDate,
  });
};

export const updateLastLoginedAt = async (uid: string) => {
  await updateDoc(getUserPath(uid), {
    lastLoginedAt: serverTimestamp(),
  });
};
