import { collection, getDocs } from "firebase/firestore";

import { FirestoreDocument } from "../constants";
import { db } from "../firebase";

import { CutInfoType } from "./cutInfo";
import { ProductType } from "./products";

export type CollectionType = {
  uid: string;
  count: number;
  cutInfo: CutInfoType;
  productData: ProductType;
};

export const getCollection: (
  uid: string,
  productId: string
) => Promise<FirestoreDocument<CollectionType>[]> = async (
  uid: string,
  productId: string
) => {
  const collections: FirestoreDocument<CollectionType>[] = [];
  // productDataを取得する処理を追加する
  const collectionRef = collection(db, "collections", uid, productId);
  const collectionSnap = await getDocs(collectionRef);
  collectionSnap.forEach((doc) => {
    collections.push({
      id: doc.id,
      ...doc.data(),
    } as FirestoreDocument<CollectionType>);
  });

  return collections;
};
