import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

export enum SaleStatus {
  BEFORE_SALE = 0,
  NOW_ON_SALE = 1,
  AFTER_SALE = 2,
}

export const convertStatusToLabel = (status: SaleStatus): string => {
  switch (status) {
    case SaleStatus.BEFORE_SALE:
      return "販売決定";
    case SaleStatus.NOW_ON_SALE:
      return "販売中";
    case SaleStatus.AFTER_SALE:
      return "販売終了";
  }
};

export const convertProductStatus = (
  open: Timestamp,
  start: Timestamp,
  end: Timestamp
): SaleStatus => {
  const now = Timestamp.now();
  if (open <= now && now < start) {
    return SaleStatus.BEFORE_SALE;
  } else if (start <= now && now <= end) {
    return SaleStatus.NOW_ON_SALE;
  } else {
    return SaleStatus.AFTER_SALE;
  }
};

export type ProductType = {
  productId: string;
  productName: string;
  anime: string;
  kujiTitle: string;
  count: number;
  productTypes: {
    SType?: string[];
    NormalType?: string[];
  };
  rate: {
    SType: number;
    NormalType: number;
  };
  price1: number;
  price10: number;
  initialDiscountRate: number;
  priceIds: {
    postage: string;
    discountOne: string;
    discountTen: string;
    one: string;
    ten: string;
  };
  open: Timestamp;
  start: Timestamp;
  end: Timestamp;
  shippingRequestDeadline: Timestamp[];
  scheduledShippingAt: string[];
  postage: number;
  payment: string[];
  copyright: string;
  company: string[];
  hashtags: string[];
  productSlides: string[];
  wchance: {
    isOpen: boolean;
    prize: string;
    applyDeadline: Timestamp;
    scheduledShippingAt: string;
  };
  kujiVideo?: {
    S: string;
    Normal: string;
  };
};

export const getProduct: (
  productId: string
) => Promise<ProductType | null> = async (productId: string) => {
  const productRef = doc(db, "products", productId);
  const productSnap = await getDoc(productRef);
  if (productSnap.exists()) {
    return productSnap.data() as ProductType;
  } else {
    return null;
  }
};

export const getProductList = async (): Promise<(ProductType & { saleStatus: SaleStatus })[]> => {
  const now = Timestamp.now();
  const productsCollection = collection(db, "products");
  const q = query(
    productsCollection,
    where("open", "<=", now),
    orderBy("open", "desc")
  );
  const querySnapshot = await getDocs(q);

  let productList: (ProductType & { saleStatus: SaleStatus })[] = [];

  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      let data = doc.data() as ProductType;
      let saleStatus = convertProductStatus(data.open, data.start, data.end);
      productList.push({ ...data, saleStatus });
    });

    productList.sort((a, b) => {
      // saleStatus に基づいてカスタムソート順を定義
      const order = { 1: 0, 0: 1, 2: 2 }; // 販売中、販売決定、販売終了の順
      const orderA = order[a.saleStatus];
      const orderB = order[b.saleStatus];

      if (orderA !== orderB) {
        return orderA - orderB;
      }

      return b.start.toDate().getTime() - a.start.toDate().getTime();
    });
  }
  return productList;
};