import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Border } from "../components/Border";
import { BaseButton } from "../components/Button/BaseButton";
import { Space } from "../components/Space";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>ページが見つかりません。</title>
      </Helmet>
      <div className="flex min-h-[calc(100vh-56px)] items-center justify-center">
        <div className="text-center">
          <h2 className="text-4xl font-bold">404</h2>
          <Space height={32} />
          <p className="text-lg font-medium">
            ページが見つかりませんでした。
            <br />
            一時的にアクセスできない状況にあるか、
            <br />
            移動もしくは削除された可能性があります。
          </p>
          <Space height={32} />
          <Border />
          <Space height={32} />
          <Link to="/">
            <BaseButton
              title="Topページへ"
              backgroundColor="#c9e900"
              textColor="#000"
            />
          </Link>
          <Space height={44} />
        </div>
      </div>
    </>
  );
};

export default NotFound;
