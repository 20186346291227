import { memo } from "react";

import { ShippedInfo } from "../../firebase/index";
import { convertFormattedDate, formatZipCode } from "../../utils";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  shippedInfo: ShippedInfo;
}

export const ShippedCard = memo(({ shippedInfo }: Props) => {
  const { productId, productName } = shippedInfo.productData;
  const { numOfAvailableShip, shippedAt, customReceiptNumber, trackingNumber } =
    shippedInfo.shipInfo;
  const {
    firstName,
    lastName,
    sei,
    mei,
    phone,
    zip,
    prefecture,
    city,
    address,
    building,
  } = shippedInfo.address;

  return (
    <BaseCard>
      <Space height={16} />
      <>
        <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
        <Space height={16} />
        <h3>{productName}</h3>
        <Space height={8} />
        <p>全{numOfAvailableShip}点</p>
      </>
      <Space height={16} />
      <div className="text-left">
        <>
          <label>発送日時</label>
          <p>{convertFormattedDate(shippedAt)}</p>
        </>
        <Space height={16} />
        <>
          <label>配送管理番号</label>
          <p>{customReceiptNumber}</p>
        </>
        <Space height={16} />
        <>
          <label>追跡番号</label>
          <p>{trackingNumber}</p>
        </>
        <Space height={16} />
        <>
          <label>配送先</label>
          <>
            <p>{sei + mei}</p>
            <p>{firstName + lastName}</p>
            <p>{phone}</p>
          </>
          <Space height={8} />
          <>
            <p>〒{formatZipCode(zip)}</p>
            <p>
              {prefecture + city + address}
              <br />
              {building}
            </p>
          </>
        </>
      </div>
      <Space height={16} />
    </BaseCard>
  );
});
