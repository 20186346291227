import { memo, useEffect, useState } from "react";

import { BaseModal } from "./BaseModal";

interface Props {
  imageCount: number;
  selectedIndex: number;
  onClose: () => void;
  images: string[];
}

export const SlideModal = memo(
  ({ imageCount, selectedIndex, onClose, images }: Props) => {
    const [currentIndex, setCurrentIndex] = useState(selectedIndex);

    const handleClickOutsideORCross = () => {
      onClose();
    };

    // インデックス更新用の関数
    const updateIndex = (direction: "next" | "prev") => {
      setCurrentIndex((prevIndex) => {
        if (direction === "prev") {
          return prevIndex > 0 ? prevIndex - 1 : imageCount - 1;
        } else {
          return (prevIndex + 1) % imageCount;
        }
      });
    };

    // キーボード操作に対応
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowLeft") {
          updateIndex("prev");
        } else if (event.key === "ArrowRight") {
          updateIndex("next");
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [imageCount]);

    // <クリックしたときの処理
    const handlePrevClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      updateIndex("prev");
    };

    // >クリックしたときの処理
    const handleNextClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      updateIndex("next");
    };

    return (
      <BaseModal onClickOutside={handleClickOutsideORCross}>
        <img src={images[currentIndex]} alt={`${currentIndex}`} />
        <div className="w-100 my-4 flex items-center justify-between">
          <p
            className="mx-4 cursor-pointer text-2xl text-white"
            onClick={handlePrevClick}
          >
            {"<"}
          </p>
          <p className="mx-2 text-base text-white">{` ${currentIndex + 1}/${imageCount}`}</p>
          <p
            className="mx-4 cursor-pointer text-2xl text-white"
            onClick={handleNextClick}
          >
            {">"}
          </p>
        </div>
      </BaseModal>
    );
  }
);
