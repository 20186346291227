import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { ProductCard } from "../components/Card/ProductCard";
import { How } from "../components/How";
import { Loading } from "../components/Loading";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { useProductId } from "../context/index";
import { getProductList, ProductType, SaleStatus } from "../firebase/index";

import NotFound from "./NotFound";

const title = "原画くじ一覧";

const ProductList = memo(() => {
  const [productList, setProductList] = useState<
    (ProductType & { saleStatus: SaleStatus })[] | null
  >(null);
  const [loading, setLoading] = useState(true);
  const { productId } = useParams();
  const { setProductId } = useProductId();

  //ProductIdを空にする(リダイレクト関連)
  useEffect(() => {
    if (!productId) {
      setProductId("");
    }
  }, [productId, setProductId]);

  //商品一覧を取得
  useEffect(() => {
    const fetch = async () => {
      const data = await getProductList();
      setProductList(data);
      setLoading(false);
    };
    fetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <>
        <Space height={24} />
        {loading ? (
          <Loading />
        ) : productList ? (
          productList.map((value) => {
            return (
              <ProductCard
                productId={value.productId}
                productName={value.productName}
                status={value.saleStatus}
                start={value.start}
                end={value.end}
                key={value.productId}
              />
            );
          })
        ) : (
          <NotFound />
        )}
        <How />
        <Share
          url="https://gengakuji.com/"
          text="ハズレなしの原画くじを引いて豪華賞品をゲット！"
          hashtags={["原画くじ"]}
        />
        <Space height={48} />
      </>
    </>
  );
});

export default ProductList;
