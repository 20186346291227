import { Timestamp } from "@firebase/firestore";
import { memo } from "react";
import { Link } from "react-router-dom";

import { convertStatusToLabel, SaleStatus } from "../../firebase/index";
import { convertFormattedDate } from "../../utils/index";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  productId: string;
  productName: string;
  status: SaleStatus;
  start: Timestamp;
  end: Timestamp;
}

const getStatusBackgroundColor = (status: SaleStatus): string => {
  switch (status) {
    case SaleStatus.BEFORE_SALE:
      return "bg-blue";
    case SaleStatus.NOW_ON_SALE:
      return "bg-red";
    case SaleStatus.AFTER_SALE:
      return "bg-gray";
  }
};

export const ProductCard = memo(
  ({ productId, productName, status, start, end }: Props) => {
    const backgroundColorClass = getStatusBackgroundColor(status);

    return (
      <Link to={productId}>
        <BaseCard>
          <Space height={24} />
          <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
          <Space height={20} />
          <h3 className="text-base text-black">{productName}</h3>
          <Space height={20} />
          <div className="text-left">
            <span
              className={`inline-block rounded p-2 text-base text-white ${backgroundColorClass}`}
            >
              {convertStatusToLabel(status)}
            </span>
            <Space height={16} />
            <span className="text-base text-black">
              {status === SaleStatus.BEFORE_SALE ? (
                <>{convertFormattedDate(start)}から</>
              ) : (
                <>{convertFormattedDate(end)}まで</>
              )}
            </span>
          </div>
          <Space height={24} />
        </BaseCard>
      </Link>
    );
  }
);
