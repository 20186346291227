import { memo, ReactNode } from "react";

import { Space } from "../Space";

type Props = {
  children: ReactNode;
  onClickOutside?: () => void;
  bgColor?: string;
  zIndex?: number;
  showCross?: boolean;
};

export const BaseModal = memo(
  ({
    children,
    onClickOutside,
    bgColor = "bg-[rgba(0,0,0,0.6)]",
    showCross = true,
  }: Props) => (
    <div
      className={`fixed left-1/2 top-1/2 z-[1000] h-screen w-full -translate-x-1/2 -translate-y-1/2 overflow-y-auto ${bgColor} text-center`}
      onClick={onClickOutside}
    >
      <div className="m-auto w-[calc(100%-48px)] max-w-[calc(720px-48px)] p-0 px-6">
        {showCross && (
          <>
            <Space height={40} />
            <div className="flex items-center justify-end">
              <p
                onClick={onClickOutside}
                className="cursor-pointer text-5xl font-medium leading-[48px] text-white"
              >
                ×
              </p>
            </div>
            <Space height={16} />
          </>
        )}
        {children}
      </div>
    </div>
  )
);
