import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { CollectionCard } from "../components/Card/CollectionCard";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { useAuthUser } from "../hooks/useAuth";
import { CollectionListType, getCollectionList } from "../utils/index";

const title = "コレクション一覧";

const CollectionList = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [collectionList, setCollectionList] =
    useState<CollectionListType>(null);
  const user = useAuthUser(); // useAuthUserフックを使用してユーザー情報を取得

  //コレクション一覧を取得
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const uid = user.uid;
        const data = await getCollectionList(uid);
        setCollectionList(data);
      } else {
        console.error("No user is signed in");
      }
      setIsLoading(false);
    };

    fetchData();
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <>
        <Space height={24} />
        <BgH2 text="コレクション" />
        {isLoading ? (
          <Loading />
        ) : collectionList === null ? (
          <>
            <Space height={24} />
            <p>コレクションがありません。</p>
            <Space height={24} />
          </>
        ) : (
          <>
            {collectionList.reverse().map((val) => (
              <CollectionCard
                key={val.product.productId}
                productId={val.product.productId}
                productName={val.product.productName}
                count={val.product.count}
                haveCount={val.collectionCount}
              />
            ))}
            <Space height={24} />
            <Link to="/mypage/history">
              <BaseButton
                title="くじ結果履歴へ"
                backgroundColor="#c9e900"
                textColor="#000"
              />
            </Link>
            <Space height={48} />
          </>
        )}
      </>
    </>
  );
});

export default CollectionList;
