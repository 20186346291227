import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { BaseViewer } from "../components/BaseViewer";
import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { Space } from "../components/Space";
import {
  CutInfoType,
  ProductType,
  getUser,
  getCutInfo,
  getProduct,
} from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { buyKuji, convertTimeStampToDate } from "../utils/index";

const SampleViewer = memo(() => {
  const [firstBuy, setFirstBuy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<string[] | null>(null);
  const [cutInfo, setCutInfo] = useState<CutInfoType | null>(null);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [mixedCount, setMixedCount] = useState(0);
  const { productId, cutId } = useParams();

  const user = useAuthUser();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid && productId) {
        const userData = await getUser(user.uid);
        if (!userData?.boughtProductIds.includes(productId)) {
          setFirstBuy(true);
        }
      }
    };
    fetchData();
  }, [user, productId]);

  useEffect(() => {
    const fetchDataAndImages = async () => {
      if (!productId || !cutId) return;

      const cutInfoData = await getCutInfo(productId, cutId);
      const productData = await getProduct(productId);

      setCutInfo(cutInfoData);
      setProductData(productData);
      setMixedCount(cutInfoData.mixedCount);
      setIsLoading(false);

      if (cutInfoData.mixedCount > 0) {
        const imageUrls = [];
        const baseUrl = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/sample/${cutInfoData.index}/`;

        for (let i = 0; i < cutInfoData.mixedCount; i++) {
          imageUrls.push(`${baseUrl}${i}.png`);
        }

        setImages(imageUrls);
      }
    };

    fetchDataAndImages();
  }, [productId, cutId]);

  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, firstBuy, productData);
  };

  return (
    <>
      <BaseViewer
        title="サンプル原画ビューア"
        images={images}
        isLoading={isLoading}
      />
      <Link to={`/${productId}/sample`}>
        <BaseButton
          title="サンプル一覧へ"
          backgroundColor="#c9e900"
          textColor="#000"
        />
      </Link>
      <Space height={128} />
      <FixedBottom
        user={user}
        productData={productData}
        start={
          productData ? convertTimeStampToDate(productData.start) : new Date()
        }
        end={productData ? convertTimeStampToDate(productData.end) : new Date()}
        firstBuy={firstBuy}
        handleBuyKuji={handleBuyKuji}
      />
    </>
  );
});

export default SampleViewer;
